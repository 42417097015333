.carosusel-img{
    width: 100%;
    height: 700px;
    object-fit: "contain";
    border-radius: 40px;
    
}

@media (max-width: 576px) {
    .img-gea{
        width: 100%;
        height: 400px;
        object-fit: "contain";
        border-radius: 40px;
    }
    .materiales-img{
        width: 100%;
        height: 400px;
        object-fit: "contain";
        border-radius: 40px;
    }
    .bio-img{
        width: 100%;
        height: 400px;
        object-fit: "contain";
        border-radius: 40px;

    }
    .instru-img{
        width: 100%;
        height: 400px;
        object-fit: "contain";
        border-radius: 40px;
    }
    .direc-img{
        width: 200px;
        height: 510px;
        object-fit: "contain";
        border-radius: 40px;
    }
    .carosusel-img {
        height: 300px;    
    }
    .carousel-caption{
        top:50%;
        transform: translateY(-50%);
    }
    .justify-content-md-center{
        justify-content: center;
    }
    .conteiner-caruosel{
        height: 300px;
        justify-Content: "center";
        align-items: "center";
        margin-Top: "50px";
    }
    .ccard-text{
        display: none;
    }
    .produc-cienti{
        display: block;
        margin:0 auto;
        background: "#0065B3";
        color: "white";
    }

}

@media screen and (min-width: 577px) {
    .conteiner-caruosel{
        height: 700px;
        justify-Content: "center";
        align-items: "center";
        margin-Top: "50px";
        max-Width:"100%"
    }
    .carousel {
        display: block;
        max-width: 900px;
        max-height: 600px;
        margin: 0 auto;
    }
   .ccard-text{
        display: block;
        text-align: center;
        margin-bottom: 20px;
   }
   
   

}
